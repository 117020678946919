import { buttonVariants } from "@/components/ui/button";
import { Link, NavLink } from "react-router";
import "./homepage-animations.css";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation('home');
  
  return (
    <>
      <section className="bg-white text-gray-800 relative overflow-hidden pb-10 z-10">
        <div className="container relative z-10">
          <div className="flex flex-col items-center text-center">
            <div className="mb-8">
              <div className="inline-block bg-gray-200 text-black px-3 py-1 text-sm font-medium rounded-full mb-4">
                { t('hero.quiz_creation_reimagined') }
              </div>
              <h1 className="text-5xl md:text-6xl font-bold leading-tight mb-4">
                <span className="relative">{ t('hero.engage_assess_excel') }</span>
              </h1>
              <p className="text-xl text-gray-600 mb-6 max-w-2xl mx-auto">
                { t('hero.create_captivating_quizzes') }
              </p>
              <div className="flex items-center justify-center gap-4">
                <Link
                  to="/studio/quizzes"
                  className={buttonVariants({ variant: "default", size: "lg", className: "bg-black text-white hover:bg-gray-900" })}
                >
                  { t('hero.get_started_for_free') }
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-7 mt-8">
          
              <div className="lg:-mr-12 col-span-2">
                <div className="bg-white rounded-lg overflow-hidden border border-gray-200 mt-4">
                  <div className="p-2 border-b border-gray-200 bg-gray-50 flex items-center">
                    <div className="flex space-x-1.5">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </div>
                  <img
                    src="/homepage/Quiz Landing Page.png"
                    alt="Quiz Landing Page"
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="col-span-3 z-10">
                <div className="bg-white rounded-lg overflow-hidden border border-gray-200">
                  <div className="p-2 border-b border-gray-200 bg-gray-50 flex items-center">
                    <div className="flex space-x-1.5">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </div>
                  <img
                    src="/homepage/quiz-question-example.png"
                    alt="Quiz Question Example"
                    className="w-full"
                  />
                </div>
              </div>


              <div className="col-span-2">
                <div className="bg-white rounded-lg overflow-hidden border border-gray-200 mt-4">
                  <div className="p-2 border-b border-gray-200 bg-gray-50 flex items-center">
                    {/* <div className="flex space-x-1.5">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div> */}
                  </div>
                  <img
                    src="/homepage/result review with wrong answer.png"
                    alt="Quiz Insights Dashboard"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="solutions" className="py-16 bg-gradient-to-br from-zinc-900 to-black text-white">
        <div className="container">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">{t('solutions.title')}</h2>
            <p className="text-zinc-400 max-w-2xl mx-auto">
              {t('solutions.description')}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('solutions.university_professors.title')}</h3>
              <p className="text-zinc-400 mb-4">
                {t('solutions.university_professors.description')}
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.university_professors.feature1')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.university_professors.feature2')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.university_professors.feature3')}</span>
                </li>
              </ul>
            </div>

            {/* Card 2 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('solutions.academic_departments.title')}</h3>
              <p className="text-zinc-400 mb-4">
                {t('solutions.academic_departments.description')}
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.academic_departments.feature1')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.academic_departments.feature2')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.academic_departments.feature3')}</span>
                </li>
              </ul>
            </div>

            {/* Card 3 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('solutions.communities.title')}</h3>
              <p className="text-zinc-400 mb-4">
                {t('solutions.communities.description')}
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.communities.feature1')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.communities.feature2')}</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">{t('solutions.communities.feature3')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-white to-zinc-50">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <img
                src="/homepage/Quiz Insights.png"
                className="rounded-lg border border-zinc-200"
                alt="QuizBase Analytics Dashboard"
              />
            </div>
            <div>
              <div className="inline-block bg-black text-white px-3 py-1 text-sm font-medium rounded-full mb-4">
                {t('benefits.title')}
              </div>
              <h2 className="text-3xl font-bold mb-6">{t('benefits.subtitle')}</h2>
              <p className="text-muted-foreground mb-8">
                {t('benefits.description')}
              </p>

              <div className="space-y-6">
                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-purple-500 to-blue-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">{t('benefits.speed.title')}</h3>
                    <p className="text-muted-foreground text-sm">
                      {t('benefits.speed.description')}
                    </p>
                  </div>
                </div>

                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-emerald-500 to-teal-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">{t('benefits.security.title')}</h3>
                    <p className="text-muted-foreground text-sm">
                      {t('benefits.security.description')}
                    </p>
                  </div>
                </div>

                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-amber-500 to-orange-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">{t('benefits.ux.title')}</h3>
                    <p className="text-muted-foreground text-sm">
                      {t('benefits.ux.description')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-gradient-to-t from-zinc-50 to-white" id="features">
        <div className="container text-center">
          <h2 className="text-2xl leading-10 font-bold">{t('features.title')}</h2>
          <p className="text-muted-foreground mb-8">
            {t('features.subtitle')}
          </p>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
            {/* Feature 1 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.question_builder.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.question_builder.description')}
              </p>
            </div>

            {/* Feature 2 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-blue-500 to-indigo-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.analytics.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.analytics.description')}
              </p>
            </div>

            {/* Feature 3 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-emerald-500 to-teal-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.secure_testing.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.secure_testing.description')}
              </p>
            </div>

            {/* Feature 4 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-pink-500 to-rose-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.automated_grading.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.automated_grading.description')}
              </p>
            </div>

            {/* Feature 5 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-amber-500 to-orange-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.rich_media.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.rich_media.description')}
              </p>
            </div>

            {/* Feature 6 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-sky-500 to-cyan-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">{t('features.cloud_sync.title')}</h3>
              <p className="text-muted-foreground">
                {t('features.cloud_sync.description')}
              </p>
            </div>
          </div>

          <div className="mt-12">
            <h3 className="text-xl font-bold mb-6">{t('features.demo_title')}</h3>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.question_builder.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.question_builder.description')}
                  </p>
                </div>
                <img
                  src="/homepage/Shuffling & Randomness.png"
                  className="w-full border-t border-zinc-200"
                  alt="Question Builder Interface"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.response_analysis.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.response_analysis.description')}
                  </p>
                </div>
                <img
                  src="/homepage/result review with wrong answer.png"
                  className="w-full border-t border-zinc-200"
                  alt="Response Analysis Dashboard"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.landing_page.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.landing_page.description')}
                  </p>
                </div>
                <img
                  src="/homepage/Quiz Landing Page.png"
                  className="w-full border-t border-zinc-200"
                  alt="Quiz Landing Page"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.settings.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.settings.description')}
                  </p>
                </div>
                <img
                  src="/homepage/Quiz Settings.png"
                  className="w-full border-t border-zinc-200"
                  alt="Quiz Settings Interface"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.results.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.results.description')}
                  </p>
                </div>
                <img
                  src="/homepage/Result Overview.png"
                  className="w-full border-t border-zinc-200"
                  alt="Results Overview Dashboard"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">{t('features.samples.answer_review.title')}</h4>
                  <p className="text-muted-foreground mb-4">
                    {t('features.samples.answer_review.description')}
                  </p>
                </div>
                <img
                  src="/homepage/result review with correct answer.png"
                  className="w-full border-t border-zinc-200"
                  alt="Correct Answer Review"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-b from-white to-zinc-50 py-12" id="pricing">
        <div className="container text-center">
          <h2 className="text-2xl leading-10 font-bold">{t('pricing.title')}</h2>
          <p className="text-muted-foreground mb-8">
            {t('pricing.subtitle')}
          </p>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {/* Free Tier */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400">
              <h3 className="text-xl font-bold">{t('pricing.free.title')}</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">{t('pricing.free.price')}</span>
                <span className="text-muted-foreground"></span>
              </div>
              <p className="text-muted-foreground mb-6">{t('pricing.free.description')}</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.free.feature1')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.free.feature2')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.free.feature3')}
                </li>
              </ul>
              <NavLink
                to="/login"
                className={buttonVariants({ variant: "outline", className: "mt-auto" })}
              >
                {t('pricing.free.cta')}
              </NavLink>
            </div>

            {/* Pro Tier */}
            <div className="flex flex-col rounded-lg border-2 border-black bg-gradient-to-b from-white to-zinc-50 p-6 transition-all">
              <div className="-mt-8 mb-4 rounded-full bg-black px-3 py-1 text-sm font-medium text-white self-start">
                {t('pricing.pro.tag')}
              </div>
              <h3 className="text-xl font-bold">{t('pricing.pro.title')}</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">{t('pricing.pro.price')}</span>
                <span className="text-muted-foreground">{t('pricing.pro.period')}</span>
              </div>
              <p className="text-sm text-muted-foreground mb-1">{t('pricing.pro.annual')}</p>
              <p className="text-muted-foreground mb-6">{t('pricing.pro.description')}</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.pro.feature1')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.pro.feature2')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.pro.feature3')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.pro.feature4')}
                </li>
              </ul>
              <NavLink
                to="/login?plan=pro"
                className={buttonVariants({ variant: "default", className: "mt-auto bg-black hover:bg-black/90" })}
              >
                {t('pricing.pro.cta')}
              </NavLink>
            </div>

            {/* Team Tier */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400">
              <h3 className="text-xl font-bold">{t('pricing.institution.title')}</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">{t('pricing.institution.price')}</span>
              </div>
              {/* <p className="text-sm text-muted-foreground mb-1">or $10/student/year (save $2)</p> */}
              <p className="text-muted-foreground mb-6">{t('pricing.institution.description')}</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.institution.feature1')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.institution.feature2')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.institution.feature3')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.institution.feature4')}
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {t('pricing.institution.feature5')}
                </li>
              </ul>
              <NavLink
                to="/login?plan=institution"
                className={buttonVariants({ variant: "outline", className: "mt-auto" })}
              >
                {t('pricing.institution.cta')}
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-r from-black to-zinc-800 text-white" id="contact">
        <div className="container">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">{t('cta.title')}</h2>
            <p className="text-xl text-zinc-300 mb-8">
              {t('cta.description')}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <NavLink
                to="/login"
                className={buttonVariants({ variant: "default", size: "lg" })}
              >
                {t('cta.get_started')}
                <svg className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
              </NavLink>
              <a
                href="mailto:contact@quizbase.com"
                className={buttonVariants({ variant: "outline", size: "lg", className: "text-black" })}
              >
                {t('cta.contact_sales')}
                <svg className="ml-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                </svg>
              </a>
            </div>
            <div className="mt-12 p-6 bg-white/10 rounded-lg backdrop-blur-sm border border-white/20">
              <div className="flex flex-col md:flex-row items-center justify-center gap-6">
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">{t('cta.free_trial')}</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">{t('cta.no_credit_card')}</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">{t('cta.cancel_anytime')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
