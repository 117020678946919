import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router";
import RootLayout from "./RootLayout.tsx";
import "./index.css";
// Web App
import HomePage from "./routes/site/Homepage.tsx";
import LoginPage from "./routes/LoginPage.tsx";
import QuizLanding from "./routes/quiz/QuizLanding.tsx";
import QuizScoreReview from "./routes/quiz/QuizScoreReview.tsx";
// Studio APP
import StudioLayout from "./routes/studio/StudioLayout.tsx";
import QuizzesIndex from "./routes/studio/quizzes/route.tsx";
import QuizQuestionsEditor from "./routes/studio/editor/questions/route.tsx";
import StudioStartPage from "./routes/studio/StartPage.tsx";
import { Toaster } from "./components/ui/sonner.tsx";
import { ErrorBoundary } from "./ErrorBoundary.tsx";
import QuizEditorLayout from "./routes/studio/editor/EditorLayout.tsx";
import QuizSettings from "./routes/studio/editor/settings/route.tsx";
import BrandSettings from "./routes/studio/branding/BrandSettings.tsx";
import * as Sentry from "@sentry/react";
import QuizResponses from "./routes/studio/editor/responses/route.tsx";
import BanksIndex from "./routes/studio/banks/route.tsx";
import TeamIndex from "./routes/studio/team/TeamIndex.tsx";
import HelpCenter from "./routes/site/help/HelpCenter.tsx";
import Error404 from "./routes/Error404.tsx";
import QuizInsights from "./routes/studio/editor/insights.tsx";
import QuizSessionPage from "./routes/session/page.tsx";
import ParticipantsList from "./routes/studio/students/route.tsx";
import ResponseDetailsPage from "./routes/studio/editor/responses/ResponseDetails.tsx";
import AboutUs from "./routes/site/AboutUs.tsx";
import PrivacyPolicy from "./routes/site/PrivacyPolicy.tsx";
import TermsOfService from "./routes/site/TermsOfService.tsx";
import { ArticleViewer } from "./routes/site/help/components/ArticleViewer.tsx";
import { CategoryViewer } from "./routes/site/help/components/CategoryViewer.tsx";
import { SearchResults } from "./routes/site/help/components/SearchResults.tsx";
import HelpCenterLayout from "./routes/site/help/HelpCenterLayout.tsx";
import ProfilePage from "./routes/site/profile/page.tsx";
import QuizOverview from "./routes/studio/editor/overview.tsx";
import GroupsIndex from "./routes/studio/groups/route.tsx";
import BankDetails from "./routes/studio/bank/BankDetails.tsx";
import PreviewQuiz from "./routes/studio/editor/preview/route.tsx";
import SiteLayout from "./routes/site/SiteLayout.tsx";
// i18n
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://fb0ac8f8f6a2b123236f18135cda733b@o4508863033835520.ingest.de.sentry.io/4508863134171216",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.PROD ? "production" : "development",
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/supabase\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

i18n
  .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    ns: ['common', 'home', 'studio'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });


export const MainApp = () => {
  return (
    <StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route element={<RootLayout />}>
              <Route element={<SiteLayout />}>
                <Route index element={<HomePage />} />
                <Route path="about" element={<AboutUs />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<TermsOfService />} />
              </Route>

              <Route path="login" element={<LoginPage />} />

              <Route path="quiz/:quizId" element={<QuizLanding />} />
              <Route path="quiz/:quizId/session/:sessionId" element={<QuizSessionPage />} />
              <Route path="quiz/:quizId/session/:sessionId/review" element={<QuizScoreReview />} />
              <Route path="profile" element={<ProfilePage />} />


              <Route path="help" element={<HelpCenterLayout />}>
                <Route index element={<HelpCenter />} />
                <Route path="search" element={<SearchResults />} />
                <Route path="category/:categoryId" element={<CategoryViewer />} />
                <Route path="article/:articleId" element={<ArticleViewer />} />
              </Route>

              {/* studio routes */}
              <Route path="studio" element={<StudioLayout />}>
                <Route index element={<QuizzesIndex />} />
                <Route path="start" element={<StudioStartPage />} />
                <Route path="quizzes" element={<QuizzesIndex />} />
                <Route path="quiz/:quizId" element={<QuizEditorLayout />}>
                  <Route index element={<QuizOverview />} />
                  <Route path="questions" element={<QuizQuestionsEditor />} />
                  <Route path="responses" element={<QuizResponses />} />
                  <Route path="insights" element={<QuizInsights />} />
                  <Route path="settings" element={<QuizSettings />} />
                  <Route path="preview" element={<PreviewQuiz />} />
                  <Route
                    path="responses/:responseId"
                    element={<ResponseDetailsPage />}
                  />
                </Route>

                <Route path="branding" element={<BrandSettings />} />
                <Route path="banks">
                  <Route index element={<BanksIndex />} />
                  <Route path=":bankId" element={<BankDetails />} />
                </Route>
                <Route path="team" element={<TeamIndex />} />
                <Route path="groups" element={<GroupsIndex />} />
                <Route path="students" element={<ParticipantsList />} />
              </Route>
              {/*  fallback route */}
              <Route path="*" element={<Error404 />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster />
      </ErrorBoundary>
    </StrictMode>
  );
};

createRoot(document.getElementById("root")!).render(<MainApp />);
