import { useAuthStore } from "@/store/auth.store";
import { Button } from "@/components/ui/button";
import { NavLink } from "react-router";
import { useTranslation } from "react-i18next";
import { LanguagesIcon } from "lucide-react";

export default function Header() {
  const { user } = useAuthStore();
  const { t, i18n } = useTranslation('home');

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
  }

  return (
    <div className="container">
      <header className="flex items-center py-4 gap-8">
        <NavLink to="/" className="inline-flex items-center gap-1">
          <div className="rounded-lg bg-black px-1.5 py-0.5 text-white">QB</div>
          <span className="text-lg font-bold text-black">{ t('common:brand') }</span>
        </NavLink>

        <nav className="hidden items-center gap-3 lg:flex">
          <a
            href="/#solutions"
            className="text-muted-foreground hover:text-black"
          >
            { t('nav.solutions') }
          </a>
          <a
            href="/#features"
            className="text-muted-foreground hover:text-black"
          >
            { t('nav.features') }
          </a>
          <a
            href="/#pricing"
            className="text-muted-foreground hover:text-black"
          >
            { t('nav.pricing') }
          </a>
        </nav>

        <div className="flex-1" />
        <nav className="flex items-center gap-3 z-20">
          <Button variant="ghost" onClick={changeLanguage}>
            <LanguagesIcon />
          </Button>
          {!user && (
            <Button asChild>
              <NavLink
                to="/login"
              >
                { t('nav.login') }
              </NavLink>
            </Button>
          )}
          {user && (
            <Button asChild>
            <NavLink
              to="/studio"
            >
              { t('nav.studio') }
              </NavLink>
            </Button>
          )}
      </nav>
    </header>
    </div >
  );
}
