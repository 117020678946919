import { supabase } from "@/lib/supabase";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
} from "@/components/ui/sidebar";
import {
  CardStackIcon,
  ExitIcon,
  LayersIcon,
  MagicWandIcon,
  PersonIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { NavLink, useLocation } from "react-router";
import { useAuthStore } from "@/store/auth.store";
import { useWorkspaceStore } from "@/store/workspace.store";
import { WorkspaceSwitcher } from "./WorkspaceSwitcher";
import { ChevronLeft, ChevronRight, LanguagesIcon, UsersIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export function StudioSidebar() {
  const { user, setUser } = useAuthStore();
  const location = useLocation();
  const clearWorkspaces = useWorkspaceStore((state) => state.clear);
  const { t, i18n } = useTranslation('studio');
  const [side, setSide] = useState<'left' | 'right'>('left');

  const logout = async () => {
    setUser(undefined);
    clearWorkspaces();
    await supabase.auth.signOut();
  };

  const changeLanguage = () => {
    setSide(side === 'left' ? 'right' : 'left');
    i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
  }

  const ChevronICon = () => (
    <>
      {i18n.language === 'ar' && <ChevronLeft className="mr-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />}
      {i18n.language === 'en' && <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />}
    </>
  )

  return (
    <Sidebar collapsible="icon" dir={i18n.dir()} side={side}>
      <SidebarHeader>
        <WorkspaceSwitcher />
      </SidebarHeader>
      <SidebarContent className="bg-white">
        <SidebarGroup>
          <SidebarGroupLabel>{t('nav.build')}</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/quiz")}
                >
                  <NavLink
                    to="/studio/quizzes"
                    className="inline-flex items-center gap-2"
                  >
                    <CardStackIcon /> {t('nav.quizzes')}
                    <ChevronICon />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/bank")}
                >
                  <NavLink
                    to="/studio/banks"
                    className="disabled inline-flex items-center gap-2"
                  >
                    <LayersIcon /> {t('nav.question_banks')}
                    <ChevronICon />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupLabel>{t('nav.manage')}</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/groups")}
                >
                  <NavLink
                    to="/studio/groups"
                    className="inline-flex items-center gap-2"
                  >
                    <UsersIcon /> {t('nav.groups')}
                    <ChevronICon />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/students")}
                >
                  <NavLink
                    to="/studio/students"
                    className="inline-flex items-center gap-2"
                  >
                    <PersonIcon /> {t('nav.students')}
                    <ChevronICon />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        
        <SidebarGroup>
          <SidebarGroupLabel>{t('nav.workspace')}</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/branding")}
                >
                  <NavLink
                    to="/studio/branding"
                    className="inline-flex items-center gap-2"
                  >
                    <MagicWandIcon /> {t('nav.branding')}
                    <ChevronICon />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>

      <SidebarFooter className="bg-white">
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              onClick={changeLanguage}
            >
              <LanguagesIcon /> {t('common:change_language')}
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              isActive={location.pathname.startsWith("/studio/help")}
            >
              <NavLink to="/help" className="inline-flex items-center gap-2">
                <QuestionMarkCircledIcon /> {t('nav.help_center')}
              </NavLink>
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarSeparator />

          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              isActive={location.pathname.startsWith("/studio/settings")}
            >
              {/* <NavLink
                to="/studio/settings"
                className="inline-flex items-center gap-2"
              >
                <GearIcon /> Settings {user?.email}
              </NavLink> */}

              <NavLink to="/studio" className="inline-flex items-center gap-2">
                <PersonIcon />
                {user?.email}
              </NavLink>
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarMenuItem>
            <SidebarMenuButton onClick={logout} className="cursor-pointer">
              <ExitIcon /> {t('common:auth.logout')}
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
