import { useEffect, useState } from "react";
import {
  GearIcon,
  Link1Icon,
  LayersIcon,
  ExternalLinkIcon,
  ChatBubbleIcon,
  BarChartIcon,
  DashboardIcon,
  Share1Icon,
  EyeOpenIcon,
} from "@radix-ui/react-icons";
import { Button, buttonVariants } from "@/components/ui/button";
import { NavLink, Outlet, useLocation, useParams } from "react-router";
import { getQuiz } from "@/lib/data/studio/quiz.service";
import { useEditorStore } from "@/store/editor.store";
import { toast } from "sonner";
import { useIsSmallScreen } from "@/hooks/use-mobile";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import * as Sentry from '@sentry/react'
import { StudioBreadcrumbs } from "@/studio/StudioBreadcrumbs";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import LoadingScreen from "@/components/LoadingScreen";
import { useTranslation } from "react-i18next";

export default function QuizEditorLayout() {
  const { quizId } = useParams();
  const location = useLocation();
  const workspaceId = useCurrentWorkspaceId();
  const { quiz, setQuiz, setIsEditable } = useEditorStore();
  const isSmallScreen = useIsSmallScreen();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation('studio');

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const quizDetails = await getQuiz(quizId!, workspaceId!);
        setQuiz(quizDetails);
        setIsEditable(quizDetails.responses[0].count === 0); // mark quiz as non-editable if it has responses
        setError(null)
      } catch (error) {
        toast.error("Failed to fetch quiz");
        setError("Failed to fetch quiz")
        Sentry.captureException(error)
      }
    };

    fetchQuiz();
  }, [quizId, setQuiz, workspaceId]);


  if (error) {
    return <div className="mx-auto bg-white border rounded p-4 max-w-lg mt-24">
      <h4 className="text-xl font-bold leading-9">Quiz not found</h4>
      <p>You don't have access to this quiz</p>
    </div>
  }

  return (
    <div className="flex min-h-screen flex-col pb-24">
      <StudioBreadcrumbs
        items={[
          { label: t('nav.quizzes'), href: `/studio/quizzes`, icon: <LayersIcon className="h-4 w-4" /> },
          { label: quiz?.title || "-" }
        ]}
      >
      </StudioBreadcrumbs>

      <nav className="flex flex-wrap gap-1 border-b bg-white px-3 py-2">
        <NavLink
          to={`/studio/quiz/${quizId}/`}
          className={`border-box inline-flex items-center gap-1 rounded-md border border-transparent px-3 py-1 text-sm hover:border ${location.pathname.endsWith(`/quiz/${quizId}/`)
            ? "border bg-black text-white"
            : "text-gray-600 hover:bg-neutral-100 hover:text-black"
            }`}
          end
          viewTransition
        >
          <DashboardIcon className="h-4 w-4" /> {!isSmallScreen && t('quiz.overview')}
        </NavLink>
        <NavLink
          to={`/studio/quiz/${quizId}/questions`}
          className={`border-box inline-flex items-center gap-1 rounded-md border border-transparent px-3 py-1 text-sm hover:border ${location.pathname === `/studio/quiz/${quizId}/questions`
            ? "border bg-black text-white"
            : "text-gray-600 hover:bg-neutral-100 hover:text-black"
            }`}
        >
          <LayersIcon /> {!isSmallScreen && t('quiz.questions')}
        </NavLink>

        <NavLink
          to={`/studio/quiz/${quizId}/responses`}
          className={`border-box inline-flex items-center gap-1 rounded-md border border-transparent px-3 py-1 text-sm hover:border ${location.pathname === `/studio/quiz/${quizId}/responses`
            ? "border bg-black text-white"
            : "text-gray-600 hover:bg-neutral-100 hover:text-black"
            }`}
          prefetch="render"
        >
          <ChatBubbleIcon className="h-3 w-3" /> {!isSmallScreen && t('quiz.responses')}
        </NavLink>

        <NavLink
          to={`/studio/quiz/${quizId}/insights`}
          className={`border-box inline-flex items-center gap-1 rounded-md border border-transparent px-3 py-1 text-sm hover:border ${location.pathname === `/studio/quiz/${quizId}/insights`
            ? "border bg-black text-white"
            : "text-gray-600 hover:bg-neutral-100 hover:text-black"
            }`}
        >
          <BarChartIcon className="h-3 w-3" /> {!isSmallScreen && t('quiz.insights')}
        </NavLink>

        <NavLink
          to={`/studio/quiz/${quizId}/settings`}
          className={`border-box inline-flex items-center gap-1 rounded-md border border-transparent px-3 py-1 text-sm hover:border ${location.pathname === `/studio/quiz/${quizId}/settings`
            ? "border bg-black text-white"
            : "text-gray-600 hover:bg-neutral-100 hover:text-black"
            }`}
        >
          <GearIcon /> {!isSmallScreen && t('quiz.settings')}
        </NavLink>
        <span className="flex-1" />
        {/* actions  */}
        <Button size="sm" variant="link" asChild>
          <NavLink to={`/studio/quiz/${quizId}/preview`}>
            <EyeOpenIcon className="h-4 w-4" /> {t('quiz.preview')}
          </NavLink>
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm" variant="secondary"><Share1Icon className="h-4 w-4" /> {t('quiz.share')}</Button></DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem asChild>
              <Button
                variant="link"
                // className="flex items-center gap-2 rounded-lg px-2 py-1 text-xs hover:bg-gray-200"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${import.meta.env.VITE_BASE_URL}/quiz/${quizId}`,
                  );
                  toast(t('quiz.copied_to_clipboard'));
                }}
              >
                <Link1Icon className="h-4 w-4" /> {t('quiz.copy_link')}
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem><a
              href={`/quiz/${quizId}`}
              className={buttonVariants({ variant: "link", size: "sm" })}
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" /> {t('quiz.open_quiz')}
            </a></DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </nav>

      <section className="">
        {quiz ? <Outlet /> : <LoadingScreen text={t('quiz.loading')} />}
      </section>
    </div >
  );
}
